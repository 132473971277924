import React from 'react';
import css from 'styled-components';
import { Reset } from 'styled-reset';

import Header from './Header';
import Footer from './Footer';

const Wrap = css.div`
  box-sizing: border-box;
  a{
    text-decoration: none;
  }
`;

const Layout = ({ children }) => {
  return (
    <>
      <Reset />
      <Wrap>
        <Header />
        {children}
        <Footer />
      </Wrap>
    </>
  );
};

export default Layout;
