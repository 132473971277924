export default {
  nav: [
    {
      name: '技术文档',
      link: 'https://docs.bystack.com/zh/',
    },
    // {
    //   name: '白皮书',
    //   link: 'https://docs.bystack.com/zh/',
    // },
    {
      name: '关于我们',
      link: 'https://docs.bystack.com/zh/guide/01_contact_us.html',
    },
    {
      name: '登录/注册',
      link: 'https://dashboard.bystack.com/',
    },
  ],
};
