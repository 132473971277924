import React from 'react';
import Layout from '../components/layout/Layout';
import css from 'styled-components';

const Wrap = css.div`
  width: 1200px;
  margin: 60px auto;
  h1{
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    margin-bottom: 50px;
  }
  h2{
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    margin: 0 0 10px;
  }
  p{
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin: 30px 0;
  }
`;

const Article = () => {
  return (
    <Layout>
      <Wrap>
        <h1>Bystack助力无界版图创作者经济</h1>
        <p>
          互联网的出现改变了传统媒体的传播方式，将少数公司垄断的媒体和娱乐逐渐拆解开，使得其更加的去中心化，但是收益的分配确仍然掌握在少数头部的公司手中，虽然从形式上，创作者的影响力越来越大，但是实际的利益分配和话语权上，创作者不占优势，从而影响了创作者经济的进一步发展。
        </p>
        <p>区块链的出现则有望重塑创作者经济：</p>
        <h2>更高的收益自主权</h2>
        <p>
          在无界版图上，绝大部分收益都由创作者获得，平台只收取少量手续费；
          通过版税制度，创作者可以一直获取后续的交易分成，版税通过Bystack智能合约来设置，无需平台同意，只需要发生交易，即可立即获得版税分润
        </p>
        <h2>更简单的版权确权</h2>
        <p>
          传统平台上，创作者无法非常方便的进行版权确权，有时也会直接贱卖。而在无界版图上，通过Bystack版权登记和上链，创作者可以直接对自己的作品进行确权，同时也可以控制自己版权的价格和售卖方式
        </p>
        <h2>更快捷的版权维权</h2>
        <p>利用Bystack区块链公开透明特性，同时对接的公证处和互联网法院，创作者可以非常快捷的发起维权并得到响应。</p>
        <p>通过以上三点，将极大的激励创作者的创作意愿，让创作者无后顾之忧，从而开创真正的创作者经济时代。</p>
      </Wrap>
    </Layout>
  );
};

export default Article;
