import { Link } from 'gatsby';
import React from 'react';
import css from 'styled-components';

import imgBg from '../../images/background.jpg';
import Logo from '../../images/logo.png';
import LogoBystack from '../../images/logo_bystack.png';

import _nav from '../../constants/nav.conf';

const Wrap = css.div`
  width: 100%;
  height: 80px;
  padding: 0 80px;
  background: url(${imgBg}) top center / 100% no-repeat;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo{
    display: flex;
    align-items: center;
    img:first-child{
      width: 32px;
    }
    img:last-child{
      width: 120px;
      margin-left: 12px;
    }
  }
  >nav{
    >a{
      margin: 0 20px;
      color: #fff;
      transition: all .2s;
      font-size: 16px;
      &:hover{
        color: rgba(255,255,255,.8);
      }
    }
  }
`;

const Header = () => {
  return (
    <Wrap>
      <Link className="logo" to="/">
        <img src={Logo} alt="logo" />
        <img src={LogoBystack} alt="" />
      </Link>
      <nav>
        {_nav.nav.map((item, index) =>
          /^https/.test(item.link) ? (
            <a target="_blank" href={item.link} key={index} rel="noreferrer">
              {item.name}
            </a>
          ) : (
            <Link to={item.link} key={index}>
              {item.name}
            </Link>
          ),
        )}
      </nav>
    </Wrap>
  );
};

export default Header;
