import React from 'react';
import css from 'styled-components';

import LogoBystack from '../../images/logo_bystack.png';

const Img = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAFDklEQVQ4EQXBbWwbZwHA8f89dz6fz2/nxLFj4tI1jdamrG1GW2AjpFuH9oLaMIJUVoQQQ2VMoDGB2AfQ+DCND+NLJaZpgMqQ6NQPwJAm2qqsy4LY1ooOphbaqi9Zmqax0zSx49hn34vPdw+/nyKlBKcDQIRAyAhXr5CIDQLtQefmhcl/nP7393y3pz/06M6jfTt2vA3DFd+XxF0XhAIIAMikUaSUBE4TFIkIJaqeA95/ePb0mWfP/v3GpHRnjUXfRM1ZbMwbuDWzN/7I8InRA1N/kOE9J6XfRioKIFGzQwiAlmLTlutIMwe6Z8z85o2Zbz8zfbDtLRj3plQOG3d51ljkwQeK9D1Q1A4/8+7Xzrz6ygklocYdXcHjPwTaxwAIAFSBUDNool72L7z8zq+PNzl0eJjvlFtkf3eF+vWQ+lxIc/9v+Wp4ieePPcXRvwbI67//S8r0DGGkMJJxAARAyrdJxpujt0++ODex/9KENVLkh/lLzB9vITZYhHYX/AhlwOLcc2c5aF6g+MX7+fL+UwdWP3zjprNwccvV9/4GgACI+y6a907qxPW0/tHSbp7YbdO+2ELcbqJYceJrId7Zu8Q29WO0XWp/PM/UY11mPtnGmx+EpZTSSSDGABAAzdVaOfrXe0ciJQGaQ8ZeorUMsTCANQ9ls4m+y0Lc6aATUZt3GerzQVFotAXxAe3I1vGpTQACYOHau1/vVqvjuZQOvTiyVCI/Pkgbif1gP+GhMqmXxjCf34EYMCnsytNxuiAlpYIJlYsPry2e+y6AADBTudGWHTA84IHRz5nzJtq+DPUDGwmrNsr/Ojin7hLMr6O/ME7fj3fy1rEGkGRsu0ZnbgWvMlcGEADR2tKBVkPjvrJN+XM6r7+Z5J+vVBjbUyJrpnGuNIhWfLxLDdLVdS6/fINXj8UpjllsH2pSq/oEa9fGAQSA0DqW3RJkVm9w8LGQXpAiOLlM/58+RgzF6A6oRAWN/L4SpZmb1I5eptNVmZqMka7PYrc0tLSIAWgACfPT02LLrcmwOMTTn7c5MjTA69VRgsUr9NdnEdkE7pUG9kiBaq7En0UCMhZPf2GFXrqM9RkFM7fhFIAGAKIgW5KlOZv7Ruf55U938eILE5y0t0MrYNKp02cJ3jqVpo0J5PjJDwz2jMyyNAt4DlHP3gmgAWhWd3cvXSRYDqisXOVH43GmppPMnHa4Jnbw+LZPKCVq9E4M0W24bNhq8vPHb3H33C2cwMDI62iWvhVAkVLitz/6Zv38r467y138WgCVBTY/OUa8aEBYJ6xrtFdcspv6YGM/VGrMv30dN50nNZwiMWiSHTm0V8995X1FSglAr/Pfb61dPvJa6LnZ2gfLaEnJ4N5RwjWXwOkgTAOjL4mix6ifm6MXamR3bybwqGe3TDyVtDLTiv4oGkDgLuCuulVvOeEaxShb3DdC2OwQ2i0wdVTdQk9Ad71Np9LA2NiPUS7irzt4VVsmNrRRMxkSgACQ3ZuofPizwmfTg7FcCb8ZEYUaUajirdogI7r1Ju3ZVRQ1BoqCd6dGLCEofqmU1037+5ELAAJAVzPE+/c8aTvZ15xKpaOr62QKPWKKg64EqP460nVJb82TGckg9DZaVkfJDd7uaff/QjPv/YaqrwKgASADlJjmtOrJ59yrxZfc5uoTfrC491PbBrZFtpIyLNGnJdLYd7idGLAcrTBxcW1uZdpdaJwZ2luIFFVFhh4A/wcuIjcTHG3F4QAAAABJRU5ErkJggg==';

const Wrap = css.div`
  background: #000;
  padding: 85px 0 45px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  a{
    color: #fff;
    &:hover{
      color: rgba(255,255,255,.8);
    }
  }
  >img{
    width: 172px;
  }
  >nav{
    display: flex;
    justify-content: center;
    margin: 32px 0 24px;
    >span{
      margin-right: 36px;
    }
    ul{
      display: flex;
      justify-content: center;
      li + li:before{
        content: '';
        display: inline-block;
        width: 1px;
        height: 12px;
        background: #fff;
        margin: 0 12px;
      }
    }
  }
  hr{
    margin: 0 10vw;
    border-bottom-width: 0;
  }
  .copy{
    margin-top: 24px;
  }
`;

const Footer = () => {
  return (
    <Wrap>
      <img src={LogoBystack} alt="" />
      <nav>
        <span>友情链接</span>
        <ul>
          <li><a target="_blank" href="https://8btc.com" rel="noreferrer">巴比特</a></li>
          <li><a target="_blank" href="https://www.chainnode.com/" rel="noreferrer">链节点</a></li>
          <li><a target="_blank" href="https://matpool.com" rel="noreferrer">矩池云</a></li>
        </ul>
      </nav>
      <hr />
      <div className='copy'>
        Copyright © {new Date().getUTCFullYear()} · 杭州超节点信息科技有限公司
        <a target="_blank" href="https://bcbeian.ifcert.cn/" rel="noreferrer"> · 浙网信备33010619213211320012号</a>
        <a target="_blank" href="https://beian.miit.gov.cn" rel="noreferrer"> · 浙ICP备14013035号-14</a>
        <br />
        <a style={{marginTop: 16, display: 'block'}} target="_blank" href="https://beian.gov.cn/portal/registerSystemInfo?recordcode=33010602010314" rel="noreferrer"><img style={{width: '17px', verticalAlign: '-3px', marginRight: '5px'}} src={Img} alt="" />浙公网安备 33010602010314号</a>
      </div>
    </Wrap>
  );
};

export default Footer;
